import { Box, Flex, Skeleton, Text } from '@chakra-ui/react'
import { useEffect, useMemo } from 'react'
import ReactMarkdown from 'react-markdown'
import { Document, Page } from 'react-pdf'
import rehypeRaw from 'rehype-raw'

import { useAppParams, useAppRoute } from 'routes/utils'

import {
  FullPageError,
  InlineLoading,
  PageHeader,
  ViewPdfReport,
} from 'components'
import { usePdfData } from 'components/PdfDownload/usePdfData'

import useReports from 'api/cms/reports/useReports'
import { Report } from 'api/types'

import useIsMobile from 'utils/useIsMobile'
import { useNavigate } from 'react-router-dom'
import resolvePath from 'utils/resolvePath'

export const CombinationsReport = ({
  report,
  title,
  reportsLoading,
  pdfData,
  type,
}: {
  report: Report | undefined
  title: string
  reportsLoading: boolean
  pdfData: Uint8Array | null | undefined
  type?: string
}) => {
  const [isMobile] = useIsMobile()
  const navigate = useNavigate()
  const { app, pageSlug, subPageSlug } = useAppParams()

  const handleReportNavigation = () => {
    //TODO: add tracking
    const path = resolvePath([app, pageSlug, subPageSlug, report?.id])
    navigate(`${path}?type=${type}`)
  }

  return (
    <Box>
      <Flex mt={isMobile ? 5 : 0} justifyContent='space-between' pt={3}>
        <PageHeader title={title} />
      </Flex>

      {reportsLoading && <InlineLoading />}

      {report && (
        <Flex
          flexDir={isMobile ? 'column' : 'row'}
          border='1px solid'
          borderColor='gray3'
          borderRadius='8px'
          p={4}
          my={6}
          gap='24px'
        >
          <Box>
            <Box _hover={{ opacity: 0.6 }} pos='relative'>
              <ViewPdfReport
                key={report.report}
                url={report.report}
                triggerComponent={({ handleClick, pdfBytes }) => {
                  const data = pdfBytes || pdfData
                  return (
                    <Box
                      borderRadius='8px'
                      overflow='hidden'
                      w='370px'
                      height='200px'
                      cursor='pointer'
                      onClick={handleReportNavigation}
                    >
                      {!report.report ? (
                        <Box
                          width='700px'
                          height='393px'
                          bg='gray.100'
                          display={'flex'}
                          justifyContent='center'
                          alignItems={'center'}
                        >
                          Upgrade to a higher tier to read the report.
                        </Box>
                      ) : !data ? (
                        <Skeleton width='700px' height='433px' />
                      ) : (
                        <Document
                          file={{ data }}
                          loading={<Skeleton width='700px' height='433px' />}
                          error={<Skeleton width='700px' height='433px' />}
                        >
                          <Page
                            width={370}
                            pageNumber={1}
                            onError={() => console.error('error')}
                          />
                        </Document>
                      )}
                    </Box>
                  )
                }}
              />
              <Box
                pos='absolute'
                bottom={'0'}
                zIndex='1'
                w='full'
                opacity={'1'}
              >
                <Text
                  fontSize={'md'}
                  textAlign='center'
                  textColor={'white'}
                  opacity='1'
                  fontWeight={'bold'}
                >
                  Click on the image to open the report
                </Text>
              </Box>
            </Box>
          </Box>
          <Box p='1rem'>
            <ReactMarkdown rehypePlugins={[rehypeRaw]}>
              {report.content}
            </ReactMarkdown>
          </Box>
        </Flex>
      )}
    </Box>
  )
}

const CombinationVaccinesReport = ({ title }: { title: string }) => {
  const selectedApp = useAppRoute()

  const reportsRes = useReports(selectedApp, ['combination-vaccines-report'])
  const reportsLoading = reportsRes.some((res) => res.isLoading)
  const reportsFetched = reportsRes.some((res) => res.isFetched)
  const isError = reportsRes.some((res) => res.isError)

  const report = useMemo(() => {
    if (reportsFetched) return reportsRes?.[0]?.data?.[0]
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reportsFetched])

  const { data: pdfData, refetch: loadPdf } = usePdfData(
    report ? report.report : ''
  )

  useEffect(() => {
    report?.report && loadPdf()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [report])

  if (isError) {
    return <FullPageError />
  }
  return (
    <CombinationsReport
      report={report}
      title={title}
      reportsLoading={reportsLoading}
      pdfData={pdfData}
      type='analyst-combination-vaccines-report'
    />
  )
}

export default CombinationVaccinesReport
