import { Checkmark, Close, ShareKnowledge } from '@carbon/icons-react'
import { Button, Flex, Text } from '@chakra-ui/react'
import { Input } from 'components'
import { useState } from 'react'
import { ArrowContainer, Popover } from 'react-tiny-popover'

type ShareStateT = 'success' | 'error' | 'loading' | undefined

function validateEmail(email: string) {
  const emailRegex =
    /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/

  return emailRegex.test(email)
}

const ShareEmailPopover = ({
  handleClick,
}: {
  handleClick: (email: string) => Promise<boolean>
}) => {
  const [email, setEmail] = useState('')
  const [sharedState, setSharedState] = useState<ShareStateT>()
  const [isOpen, setIsPopoverOpen] = useState(false)

  const onClick = async () => {
    setSharedState('loading')
    const _sharedState = await handleClick(email)
    setSharedState(_sharedState ? 'success' : 'error')
  }

  function onClose() {
    setEmail('')
    setIsPopoverOpen(false)
    setSharedState(undefined)
  }

  const ShareReportAction = () => {
    const validEmail = validateEmail(email)

    if (sharedState === 'error') {
      return (
        <Button onClick={onClick} variant={'error'}>
          <Checkmark size={16} color='white' />{' '}
          <Text ml='6px'>Something went wrong</Text>
        </Button>
      )
    }
    if (sharedState === 'success') {
      return (
        <Button onClick={onClick} variant={'success'}>
          <Checkmark size={16} color='white' />{' '}
          <Text ml='6px'>Shared successful</Text>
        </Button>
      )
    }
    return (
      <Button
        variant={'yellow'}
        onClick={onClick}
        disabled={!validEmail}
        opacity={validEmail ? 1 : 0.5}
      >
        {sharedState === 'loading' ? (
          <>
            <span className='loader' /> Sharing
          </>
        ) : (
          'Share report'
        )}
      </Button>
    )
  }

  return (
    <Popover
      isOpen={isOpen}
      positions={['right', 'left', 'bottom', 'top']}
      content={({ position, childRect, popoverRect }) => (
        <ArrowContainer
          position={position}
          childRect={childRect}
          popoverRect={popoverRect}
          arrowColor='lightgrey'
          arrowSize={10}
          arrowStyle={{
            opacity: 0.7,
          }}
        >
          <Flex
            bgColor='white'
            maxW={'500px'}
            marginTop='24px'
            borderRadius={15}
            p='24px'
            fontSize={'sm'}
            boxShadow='lg'
            border={'1px solid'}
            borderColor='gray.200'
            color='black'
            flexDir={'column'}
            pos='relative'
          >
            <Button
              variant={'outline'}
              pos='absolute'
              right='1rem'
              top='1rem'
              border='1px solid'
              borderColor={'gray3'}
              px='6px'
              h='24px'
              onClick={onClose}
            >
              <Close size={14} color='#0B0B0B' />
            </Button>
            <Text fontSize={'xl'} mb='1rem'>
              Report sharing.
            </Text>
            <Text>
              Write the email of the person you want to share the report with.
            </Text>
            <Text color='gray.800' mb='1rem'>
              This person will receive an email with a link to access the
              report.
            </Text>
            <Input
              placeholder='user@company.com'
              mb='1rem'
              onChange={(e) => setEmail(e.target.value)}
              value={email}
              type='email'
            />
            <ShareReportAction />
          </Flex>
        </ArrowContainer>
      )}
    >
      <Button
        onClick={() => setIsPopoverOpen((x) => !x)}
        variant='yellow'
        gap='8px'
      >
        <ShareKnowledge size={14} />
        <Text>Share</Text>
      </Button>
    </Popover>
  )
}

export default ShareEmailPopover
