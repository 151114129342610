import { useEffect } from 'react'

import { useAppRoute } from 'routes/utils'

import { ReportNotUpdated } from 'components/AsyncState/Error/ReportNotUpdated'
import { usePdfData } from 'components/PdfDownload/usePdfData'

import { useReportById } from 'api/cms/reports/useReports'

import { CombinationsReport } from 'config/apps/Covid19/Science360/Vaccines/CombinationVaccinesReports'

const SingleReportByID = ({
  title,
  id,
  note,
}: {
  title: string
  id: string
  note?: string
}) => {
  const selectedApp = useAppRoute()

  const { data, isLoading, isError } = useReportById(selectedApp, id)

  const { data: pdfData, refetch: loadPdf } = usePdfData(
    data ? data.report : ''
  )

  useEffect(() => {
    data?.report && loadPdf()
  }, [data, loadPdf])

  if (isError) {
    return <ReportNotUpdated />
  }
  return (
    <CombinationsReport
      report={data}
      title={title}
      reportsLoading={isLoading}
      pdfData={pdfData}
      type='analyst-updates-hidden'
    />
  )
}

export default SingleReportByID
