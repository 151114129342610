import { Box, Button, Flex } from '@chakra-ui/react'
import { memo } from 'react'

import { Report } from 'api/types'

import useTracking from 'tracking/useTracking'
import { useNavigate } from 'react-router-dom'
import { useAppParams } from 'routes/utils'
import resolvePath from 'utils/resolvePath'

function ReportViewOrDownload({ report }: { report: Report }) {
  const navigate = useNavigate()
  const { app, pageSlug, subPageSlug } = useAppParams()

  const [tracking] = useTracking()
  if (!report) {
    return null
  }

  const handleReportNavigation = () => {
    //TODO: add tracking
    const path = resolvePath([app, pageSlug, subPageSlug, report.id])
    navigate(`${path}`)
  }

  return (
    <Box borderBottomRadius='8px' bg='gray.50'>
      <Flex justifyContent='flex-end' px={5} alignItems='center'>
        <Button onClick={handleReportNavigation} variant='outline' my='4px'>
          View Report
        </Button>
      </Flex>
    </Box>
  )
}

export default memo(ReportViewOrDownload)
