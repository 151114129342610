import { INavigationMenu } from 'interfaces/navigationMenu.interface'

export type TreatmentsSlugs =
  | ''
  | 'candidates'
  | 'table'
  | 'pipeline'
  | 'new-science'
  | 'candidate-papers'
  | 'reports'
  | 'trials'
  | 'all-data'
  | 'recent-trials-map'
  | 'results'
  | 'variant-impact'
  | 'news'
  | 'approvals'
  | 'dashboard'
  | 'by-treatment'
  | 'by-country'
  | 'progress-tracker'
  | 'overall-progress'
  | 'metric-breakdown'
  | 'publications'
  | 'research-briefs'
  | 'dashboard'
  | 'conferences'
  | 'debriefs'
  | 'deep-dives'

const TreatmentsMenu: Array<INavigationMenu<TreatmentsSlugs>[]> = [
  [
    {
      title: 'Latest Information',
      slug: '',
      createdAt: new Date(2021, 7, 17),
    },
  ],
  [
    {
      title: 'Candidates',
      slug: 'candidates',
      createdAt: new Date(2020, 7, 26),
      children: [
        {
          title: 'Dashboard',
          slug: 'dashboard',
          createdAt: new Date(2024, 8, 6),
        },
        {
          title: 'Progress Tracker',
          slug: 'progress-tracker',
          createdAt: new Date(2022, 5, 9),
        },
      ],
    },
    {
      title: 'Conference Coverage',
      slug: 'conferences',
      description: '',
      createdAt: new Date(2024, 10, 13),
      children: [
        {
          title: 'Daily Debriefs',
          slug: 'debriefs',
          createdAt: new Date(2024, 10, 13),
        },
        {
          title: 'Conference Deep Dives',
          slug: 'deep-dives',
          createdAt: new Date(2024, 10, 13),
        },
      ],
    },
    {
      title: 'New Science',
      slug: 'new-science',
      createdAt: new Date(2020, 7, 26),
      children: [
        {
          title: 'Publications',
          slug: 'publications',
          createdAt: new Date(2020, 7, 26),
        },
      ],
    },
    {
      title: 'Trials',
      slug: 'trials',
      createdAt: new Date(2020, 7, 26),
      children: [
        {
          title: 'Table',
          slug: 'table',
          createdAt: new Date(2020, 7, 26),
        },
        {
          title: 'Recent Trials Map',
          slug: 'recent-trials-map',
          createdAt: new Date(2022, 2, 29),
        },
      ],
    },
    {
      title: 'Results',
      slug: 'results',
      createdAt: new Date(2020, 11, 22),
      children: [
        {
          title: 'Table',
          slug: 'table',
          createdAt: new Date(2020, 11, 22),
        },
        {
          title: 'Variant Impact',
          slug: 'variant-impact',
          createdAt: new Date(2021, 5, 1),
        },
      ],
    },
    {
      title: 'Approvals',
      slug: 'approvals',
      createdAt: new Date(2021, 4, 20),
      children: [
        {
          title: 'Dashboard',
          slug: 'dashboard',
          createdAt: new Date(2022, 3, 28),
        },
        {
          title: 'By Treatment',
          slug: 'by-treatment',
          createdAt: new Date(2021, 4, 20),
        },
        {
          title: 'By Country',
          slug: 'by-country',
          createdAt: new Date(2022, 2, 7),
        },
      ],
    },
    {
      title: 'Reports',
      slug: 'reports',
      createdAt: new Date(2020, 1, 24),
      children: [
        {
          title: 'Reports',
          slug: '',
          createdAt: new Date(2020, 1, 24),
        },
        {
          title: 'Research Briefs',
          slug: 'research-briefs',
          createdAt: new Date(2020, 1, 24),
        },
      ],
    },
  ],
]

export { TreatmentsMenu }
