import { Box, Flex, InputGroup } from '@chakra-ui/react'
import _ from 'lodash'
import { useEffect, useMemo, useState } from 'react'

import { useAppRoute } from 'routes/utils'

import { InlineLoading, PageHeader } from 'components'

import { BriefsT } from 'api/cms/briefs/types'
import { useBriefs } from 'api/cms/briefs/useBriefs'

import useTracking from 'tracking/useTracking'

import useIsMobile from 'utils/useIsMobile'
import useThrottle from 'utils/useThrottle'

import { groupBriefsByMonth } from '../utils'
import MainCard from './MainCard'
import MiniCard from './MiniCard'
import NothingFound from './NothingFound'
import { ReportSearchInput } from './ReportSearchInput'
import SlideModal from './SlideModal'

const ResearchBriefsPage = () => {
  const [isMobile] = useIsMobile()
  const [tracking] = useTracking()
  const selectedApp = useAppRoute()

  const [search, setSearch] = useState('')
  const searchString = useThrottle(search, 200)
  const [selectedBriefApps, setBriefApps] = useState([''])
  const [sortOrder, setSortOrder] = useState<'asc' | 'desc'>('desc')
  const [selected, setSelected] = useState<BriefsT | null>(null)

  const briefsRes = useBriefs(selectedApp)

  const isLoading = briefsRes.isLoading

  const rawBriefs = briefsRes.data as BriefsT[]

  const filteredRawBriefs = useMemo(() => {
    return (
      rawBriefs &&
      rawBriefs.filter((brief) =>
        ['title', 'description'].some((key) =>
          String(brief[key as keyof typeof brief])
            .toLowerCase()
            .includes(searchString.toLowerCase())
        )
      )
    )
  }, [rawBriefs, searchString])

  const sortedBriefs = useMemo(
    () =>
      _.orderBy(filteredRawBriefs, 'date', sortOrder).filter((brief) =>
        brief?.apps.some((item) => selectedBriefApps.includes(item))
      ),
    [filteredRawBriefs, sortOrder, selectedBriefApps]
  )
  const groupedBriefs = useMemo(
    () => groupBriefsByMonth(sortedBriefs),
    [sortedBriefs]
  )

  const briefsApps = useMemo(
    () => [...new Set(rawBriefs?.map((brief) => brief?.apps || '').flat())],
    [rawBriefs]
  )

  useEffect(() => {
    setBriefApps(briefsApps)
  }, [briefsApps])

  return (
    <Box overflow='auto' px={6} py={3}>
      <Box flex={1} pt={3} display='flex' flexDirection='column'>
        <PageHeader title={'Research Briefs'} />
        <InputGroup>
          <ReportSearchInput
            isMobile={isMobile}
            onBlur={() =>
              searchString &&
              tracking.searchResearchBriefs({
                app: selectedApp,
                searchValue: searchString,
              })
            }
            showCategory={false}
            onChange={(e: any) => setSearch(e.target.value)}
          />
        </InputGroup>

        <SlideModal
          isMobile={isMobile}
          selected={
            selected && {
              id: selected.id,
              title: selected.title,
              date: new Date(selected.datePublished),
              content: `${selected.description}`,
              summary: '',
              tableContent: selected.description,
              report: selected.pdf,
              reportName: '',
              apps: [],
            }
          }
          type={'ResearchBrief'}
          setSelected={() => setSelected(null)}
        />

        <Box border='1px solid' borderColor='gray3' borderRadius='8px' my={6}>
          {isLoading ? (
            <Flex alignItems='center' justifyContent='center' height='68px'>
              <InlineLoading />
            </Flex>
          ) : (
            <>
              <Flex
                justifyContent='space-between'
                minHeight='68px'
                px={8}
                alignItems='center'
              >
                {/* <SortMenu sortOrder={sortOrder} setSortOrder={setSortOrder} /> */}
              </Flex>
              {Object.keys(groupedBriefs).length > 0 ? (
                Object.keys(groupedBriefs).map((key, index) => {
                  return (
                    <MainCard key={index} keyStr={key}>
                      {groupedBriefs[key].map((brief, i) => {
                        if (!brief) return null
                        const { title } = brief
                        return (
                          <MiniCard
                            isMobile={isMobile}
                            key={i}
                            type='ResearchBrief'
                            onClick={() => {
                              tracking.openResearchBriefModal({
                                app: selectedApp,
                                title,
                              })
                              setSelected(brief)
                            }}
                            report={{
                              id: brief.id,
                              title,
                              date: new Date(brief.datePublished),
                              content: brief.description,
                              summary: null,
                              tableContent: '',
                              report: brief.pdf,
                              reportName: '',
                              apps: [],
                            }}
                          />
                        )
                      })}
                    </MainCard>
                  )
                })
              ) : (
                <NothingFound searchString={searchString} briefs />
              )}
            </>
          )}
        </Box>
      </Box>
    </Box>
  )
}

export default ResearchBriefsPage
