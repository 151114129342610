import { INavigationMenu } from 'interfaces/navigationMenu.interface'

export type Covid19CommercialVaccinesSlugs =
  | ''
  | 'deals'
  | 'revenue'
  | 'demand'
  | 'vaccination-campaigns'
  | 'reports'
  | 'production'
  | 'research-briefs'
  | 'vaccine-uptake'
  | 'demand-report'
  | 'supply'
  | 'table'
  | 'dashboard'
  | 'pricing-dashboard'

const Covid19CommercialVaccinesMenu: Array<
  INavigationMenu<Covid19CommercialVaccinesSlugs>[]
> = [
  [
    {
      title: 'Demand',
      slug: 'demand',
      createdAt: new Date(2024, 8, 2),
      children: [
        {
          title: 'Vaccine Uptake Dashboard',
          slug: 'vaccine-uptake',
          createdAt: new Date(2024, 8, 2),
        },
        {
          title: 'Vaccine Demand Reports',
          slug: 'demand-report',
          createdAt: new Date(2024, 8, 2),
        },
        {
          title: 'Vaccine Campaigns Table',
          slug: 'vaccination-campaigns',
          createdAt: new Date(2024, 5, 8),
        },
      ],
    },
    {
      title: 'Manufacturing & Production',
      slug: 'production',
      description: '',
      createdAt: new Date(2023, 8, 15),
      children: [
        {
          title: 'Manufacturing and Production Table',
          slug: 'table',
          createdAt: new Date(2023, 8, 15),
        },
      ],
    },
    {
      title: 'Supply & Pricing',
      slug: 'supply',
      createdAt: new Date(2023, 8, 15),
      children: [
        {
          title: 'Supply & Pricing Table',
          slug: 'table',
          createdAt: new Date(2023, 8, 15),
        },
        {
          title: 'Pricing Dashboard',
          slug: 'pricing-dashboard',
          createdAt: new Date(2024, 8, 2),
        },
      ],
    },
    {
      title: 'Revenue',
      slug: 'revenue',
      createdAt: new Date(2023, 2, 9),
      children: [
        {
          title: 'Revenue Table',
          slug: 'table',
          createdAt: new Date(2024, 8, 2),
        },
        {
          title: 'Revenue Dashboard',
          slug: 'dashboard',
          createdAt: new Date(2024, 8, 2),
        },
      ],
    },
    {
      title: 'Reports',
      slug: 'reports',
      createdAt: new Date(2022, 3, 13),
      children: [
        { title: 'Reports', slug: '', createdAt: new Date(2022, 3, 13) },
        {
          title: 'Research Briefs',
          slug: 'research-briefs',
          createdAt: new Date(2022, 7, 24),
        },
      ],
    },
  ],
]

export { Covid19CommercialVaccinesMenu }
