import { Box } from '@chakra-ui/react'

import { AppParams } from 'routes/utils'

import { INavigationMenu } from 'interfaces/navigationMenu.interface'

import { apps, appsListUnion } from 'config/apps'

import { isNew } from 'utils/isNew'
import resolvePath from 'utils/resolvePath'

import ExpandableBody from '../Expandable/ExpandableBody'
import { ExpandableContainer } from '../Expandable/ExpandableContainer'
import ExpandableHeader from '../Expandable/ExpandableHeader'
import NewTag from './NewTag'
import SimpleNavigationEntry from './SimpleNavigationEntry'
import _ from 'lodash'

export type ExpandableNavigationEntryPropTypes = {
  appParams: AppParams
  menu: INavigationMenu<any>
  defaultIsOpen: boolean
  onSelect?: (childMenuSlug: string) => void
  isFirst?: boolean
  isLast?: boolean
}

function checkIfActive(
  menu: string,
  pageSlug: string | undefined,
  subPageSlug: string | undefined,
  childMenu: string
) {
  const isIdPath = !childMenu && _.isNumber(+(subPageSlug as any))
  const isActive = menu === pageSlug && (childMenu === subPageSlug || isIdPath)
  return isActive
}

export default function ExpandableNavigationEntry({
  appParams,
  menu,
  defaultIsOpen,
  isFirst,
  isLast,
  onSelect,
}: ExpandableNavigationEntryPropTypes) {
  const { app, pageSlug, subPageSlug } = appParams
  const selectedApp = apps[app as appsListUnion]
  const menuIsNew = isNew(menu.createdAt, menu.newUntil)
  const childMenuIsNew = menu.children?.some((childMenu) => {
    return isNew(childMenu.createdAt, childMenu.newUntil)
  })

  const children = menu?.children
  return (
    <ExpandableContainer key={menu.slug} defaultIsOpen={defaultIsOpen}>
      <ExpandableHeader
        icon={menuIsNew || childMenuIsNew ? <NewTag /> : null}
        isLast={isLast}
      >
        {menu.title}
      </ExpandableHeader>
      <ExpandableBody>
        {children?.map((childMenu, i, children) => {
          return (
            <Box key={i}>
              <SimpleNavigationEntry
                isFirst={isFirst}
                isLast={isLast && i + 1 === children.length}
                isDropdownChild
                menu={childMenu}
                isActive={checkIfActive(
                  menu.slug,
                  pageSlug,
                  subPageSlug,
                  childMenu.slug
                )}
                link={resolvePath([
                  selectedApp.slug,
                  menu.slug,
                  childMenu.slug,
                ])}
                onSelect={() => {
                  onSelect?.(childMenu.slug)
                }}
                hasAccess
              />
            </Box>
          )
        })}
      </ExpandableBody>
    </ExpandableContainer>
  )
}
