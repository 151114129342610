import FullReportPage from 'routes/common/FullReportPage'
import ResearchBriefsPage from 'routes/common/FullReportPage/components/ResearchBriefs'

import { TableauEmbed } from 'components'

import { ISingleCustomPage } from 'interfaces/navigationPage.interface'

import { InfluenzaCommercialSlugs } from './InfluenzaCommercialMenu'

const Reports: ISingleCustomPage<InfluenzaCommercialSlugs> = {
  path: ['reports'],
  component: () => <FullReportPage showCategory={false} />,
}

const ResearchBriefs: ISingleCustomPage<InfluenzaCommercialSlugs> = {
  path: ['reports', 'research-briefs'],
  component: () => <ResearchBriefsPage />,
}

const SupplyDashboard: ISingleCustomPage<InfluenzaCommercialSlugs> = {
  path: ['supply-pricing', 'supply-dashboard'],
  component: () => (
    <TableauEmbed path='/InfluenzaSupply/InfluenzaSupplyDashboard' />
  ),
}

const PricingDashboard: ISingleCustomPage<InfluenzaCommercialSlugs> = {
  path: ['supply-pricing', 'pricing-dashboard'],
  component: () => (
    <TableauEmbed path='/InfluenzaPricing/InfluenzaPricingDashboard' />
  ),
}

const DemandForecastPage: ISingleCustomPage<InfluenzaCommercialSlugs> = {
  path: ['demand', 'forecast'],
  component: () => (
    <TableauEmbed path='/DataCollectionExplanation/InfluenzaVaccineCoverage' />
  ),
}

const RevenueDashboard: ISingleCustomPage<InfluenzaCommercialSlugs> = {
  path: ['revenue', 'dashboard'],
  component: () => <TableauEmbed path='/InfluenzaRevenue/InfluenzaRevenue' />,
}

const TendersDashboard: ISingleCustomPage<InfluenzaCommercialSlugs> = {
  path: ['tenders', 'dashboard'],
  component: () => (
    <TableauEmbed path='/InfluenzaTenders/InfluenzaTendersDashboard' />
  ),
}

export const InfluenzaCommercialCustomPages: Record<
  string,
  ISingleCustomPage<InfluenzaCommercialSlugs>
> = {
  Reports,
  ResearchBriefs,
  SupplyDashboard,
  PricingDashboard,
  DemandForecastPage,
  RevenueDashboard,
  TendersDashboard,
}
