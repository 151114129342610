import TabbedDisplay from 'modules/TabbedDisplay'

import FullReportPage from 'routes/common/FullReportPage'
import ResearchBriefsPage from 'routes/common/FullReportPage/components/ResearchBriefs'
import Dashboard from 'routes/dashboard'
import WidgetComponentArticle from 'routes/dashboard/WidgetComponents/WidgetArticle'

import { TableauEmbed } from 'components'

import { ISingleCustomPage } from 'interfaces/navigationPage.interface'

import CombinationVaccinesReport from 'config/apps/Covid19/Science360/Vaccines/CombinationVaccinesReports'
import { getMediaDefaultWidget } from 'config/common/baseModel/Media'
import { getPressReleasesDefaultWidget } from 'config/common/baseModel/PressReleases'

import { InfluenzaSlugs } from './InfluenzaMenu'
import { InfluenzaPages } from './InfluenzaPages'
import NewNotePopover from 'components/PageHeader/NewNotePopover'
import { Box } from '@chakra-ui/react'
import { warningMessages } from './InfluenzaWarningMessages'
import NewsletterPage from 'routes/common/FullReportPage/components/NewsletterPage'
import SingleReportByID from 'routes/common/SingleReportByID'

const DashboardPage: ISingleCustomPage<InfluenzaSlugs> = {
  path: '',
  component: () => (
    <Dashboard
      widgetTabs={[
        getMediaDefaultWidget({
          view: InfluenzaPages['InfluenzaNewsMedia'].views[0],
        }),
        getPressReleasesDefaultWidget({
          view: InfluenzaPages['InfluenzaNewsPressReleases'].views[0],
        }),
        {
          heading: 'Candidate Papers',
          body: (
            <WidgetComponentArticle
              model={InfluenzaPages['InfluenzaCandidatePapers'].model}
              view={InfluenzaPages['InfluenzaCandidatePapers'].views[0]}
              url='doiUrl'
              title='title'
              date='publicationDate'
            />
          ),
        },
      ]}
    />
  ),
}

const ConferencesDebriefs: ISingleCustomPage<InfluenzaSlugs> = {
  path: ['conferences', 'debriefs'],
  component: () => (
    <>
      <NewNotePopover
        message={`Our daily debriefs, covering all of the important scientific and commercial content during conferences, 
          are now available on the platform. If you would like a briefing on our conference insights, 
          please speak to your account manager or email infectiousdiseases@airfinity.com`}
        newUntil={new Date(2024, 11, 1)}
      />
      <NewsletterPage
        endpoint='/lzdb/influenza/newsletters/conference'
        summaryRowIndex={6}
        title='Conferences Debriefs'
      />
    </>
  ),
}
const ConferencesDeepDives: ISingleCustomPage<InfluenzaSlugs> = {
  path: ['conferences', 'deep-dives'],
  component: () => (
    <>
      <NewNotePopover
        message={`Our daily debriefs, covering all of the important scientific and commercial content during conferences, 
      are now available on the platform. If you would like a briefing on our conference insights, 
      please speak to your account manager or email infectiousdiseases@airfinity.com`}
        newUntil={new Date(2024, 11, 1)}
      />
      <SingleReportByID title='Conferences Deep Dives' id='1547' />
    </>
  ),
}

const ProgressTracker: ISingleCustomPage<InfluenzaSlugs> = {
  path: ['candidates', 'progress-tracker'],
  component: () => (
    <TabbedDisplay
      tabs={[
        {
          heading: 'Vaccines',
          body: (
            <TableauEmbed path='/WIPfluprogresstracker/VAXOverallProgressbyPhase' />
          ),
        },
        {
          heading: 'Non-Vaccines',
          body: (
            <TableauEmbed path='/WIPfluprogresstracker/TRXOverallProgressbyPhase' />
          ),
        },
      ]}
    />
  ),
}

const RecentTrialsMap: ISingleCustomPage<InfluenzaSlugs> = {
  path: ['trials', 'recent-trials-map'],
  component: () => (
    <TableauEmbed
      path='/Clinical_trials_COVID_Flu_RSV/Clinicaltrials_Flu'
      height='170vh'
    />
  ),
}

const Reports: ISingleCustomPage<InfluenzaSlugs> = {
  path: ['reports'],
  component: () => (
    <FullReportPage
      showCategory={false}
      slugOverwrites={['deep-dive']}
      researchBrief
    />
  ),
}
const CombinationsReports: ISingleCustomPage<InfluenzaSlugs> = {
  path: ['combination-reports'],
  component: () => (
    <TabbedDisplay
      tabs={[
        {
          heading: 'Read Latest Combination Vaccines Analysis',
          body: (
            <CombinationVaccinesReport title='Combination Vaccines Approval Timelines and Results' />
          ),
        },
        {
          heading: 'Combination Vaccine Results Dashboard',
          body: (
            <TableauEmbed path='/Combinationcomparativeimmunogenicity_17195769565230/Dashboard1_1' />
          ),
        },
      ]}
    />
  ),
}
const ResearchBriefs: ISingleCustomPage<InfluenzaSlugs> = {
  path: ['reports', 'research-briefs'],
  component: () => <ResearchBriefsPage />,
}

const TotalCountryVaccineDashboard: ISingleCustomPage<InfluenzaSlugs> = {
  path: ['approvals', 'map'],
  component: () => (
    <TableauEmbed
      path='/InfluenzaApprovals_16938173444670/InfluenzaApprovals'
      height='180vh'
    />
  ),
}

const VaccineComparativeImmunogenicityDashboard: ISingleCustomPage<InfluenzaSlugs> =
  {
    path: ['results', 'comparative-immunogenicity-dashboard'],
    component: () => (
      <Box>
        <NewNotePopover
          message={warningMessages.comparativeDashboardNewNote}
          newUntil={new Date(2024, 8, 27)}
        />
        <TableauEmbed
          height={'90vh'}
          path='/InfluenzaImmunogenicityFinal/Dashboard3'
        />
      </Box>
    ),
  }

export const InfluenzaCustomPages: Record<
  string,
  ISingleCustomPage<InfluenzaSlugs>
> = {
  DashboardPage,
  ProgressTracker,
  RecentTrialsMap,
  Reports,
  TotalCountryVaccineDashboard,
  CombinationsReports,
  ResearchBriefs,
  VaccineComparativeImmunogenicityDashboard,
  ConferencesDeepDives,
  ConferencesDebriefs,
}
