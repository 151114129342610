import TabbedDisplay from 'modules/TabbedDisplay'

import FullReportPage from 'routes/common/FullReportPage'
import ResearchBriefsPage from 'routes/common/FullReportPage/components/ResearchBriefs'
import Dashboard from 'routes/dashboard'
import WidgetComponentArticle from 'routes/dashboard/WidgetComponents/WidgetArticle'

import { TableauEmbed } from 'components'

import { ISingleCustomPage } from 'interfaces/navigationPage.interface'

import CombinationVaccinesReport from 'config/apps/Covid19/Science360/Vaccines/CombinationVaccinesReports'
import { getMediaDefaultWidget } from 'config/common/baseModel/Media'
import { getPressReleasesDefaultWidget } from 'config/common/baseModel/PressReleases'

import { RSVSlugs } from './RSVMenu'
import { RSVPages } from './RSVPages'
import NewsletterPage from 'routes/common/FullReportPage/components/NewsletterPage'
import NewNotePopover from 'components/PageHeader/NewNotePopover'
import SingleReportByID from 'routes/common/SingleReportByID'

const DashboardPage: ISingleCustomPage<RSVSlugs> = {
  path: '',
  component: () => (
    <Dashboard
      widgetTabs={[
        getMediaDefaultWidget({ view: RSVPages['RSVNewsMedia'].views[0] }),
        getPressReleasesDefaultWidget({
          view: RSVPages['RSVNewsPressReleases'].views[0],
        }),
        {
          heading: 'Candidate Papers',
          body: (
            <WidgetComponentArticle
              model={RSVPages['RSVCandidatePapers'].model}
              view={RSVPages['RSVCandidatePapers'].views[0]}
              url='doiUrl'
              title='title'
              date='publicationDate'
            />
          ),
        },
      ]}
    />
  ),
}

const ProgressTracker: ISingleCustomPage<RSVSlugs> = {
  path: ['candidates', 'progress-tracker'],
  component: () => (
    <TabbedDisplay
      tabs={[
        {
          heading: 'Vaccines',
          body: (
            <TableauEmbed path='/WIPRSVprogresstracker/VAXOverallProgressbyPhase' />
          ),
        },
        {
          heading: 'Non-Vaccines',
          body: (
            <TableauEmbed path='/WIPRSVprogresstracker/TRXOverallProgressbyPhase' />
          ),
        },
      ]}
    />
  ),
}

const Reports: ISingleCustomPage<RSVSlugs> = {
  path: ['reports'],
  component: () => (
    <FullReportPage showCategory={false} slugOverwrites={['deep-dive']} />
  ),
}

const CombinationsReports: ISingleCustomPage<RSVSlugs> = {
  path: ['combination-reports'],
  component: () => (
    <CombinationVaccinesReport title='Combination Vaccines Approval Timelines and Results' />
  ),
}
const ResearchBriefs: ISingleCustomPage<RSVSlugs> = {
  path: ['reports', 'research-briefs'],
  component: () => <ResearchBriefsPage />,
}

const RecentTrialsMap: ISingleCustomPage<RSVSlugs> = {
  path: ['trials', 'recent-trials-map'],
  component: () => (
    <TableauEmbed
      path='/Clinical_trials_COVID_Flu_RSV/Clinicaltrials_RSV_1'
      height='270vh'
    />
  ),
}
const ApprovalsMapDashboard: ISingleCustomPage<RSVSlugs> = {
  path: ['approvals', 'map'],
  component: () => <TableauEmbed path='/RSVapprovalsmap/IDA360Approvals' />,
}

const ConferencesDebriefs: ISingleCustomPage<RSVSlugs> = {
  path: ['conferences', 'debriefs'],
  component: () => (
    <>
      <NewNotePopover
        message={`Our daily debriefs, covering all of the important scientific and commercial content during conferences, 
          are now available on the platform. If you would like a briefing on our conference insights, 
          please speak to your account manager or email infectiousdiseases@airfinity.com`}
        newUntil={new Date(2024, 11, 1)}
      />
      <NewsletterPage
        endpoint='/lzdb/rsv/newsletters/conference'
        summaryRowIndex={6}
        title='Conferences Newsletters'
      />
    </>
  ),
}
const ConferencesDeepDives: ISingleCustomPage<RSVSlugs> = {
  path: ['conferences', 'deep-dives'],
  component: () => (
    <>
      <NewNotePopover
        message={`Our daily debriefs, covering all of the important scientific and commercial content during conferences, 
      are now available on the platform. If you would like a briefing on our conference insights, 
      please speak to your account manager or email infectiousdiseases@airfinity.com`}
        newUntil={new Date(2024, 11, 1)}
      />
      <SingleReportByID title='Conferences Deep Dives' id='1546' />
    </>
  ),
}

export const RSVCustomPages: Record<string, ISingleCustomPage<RSVSlugs>> = {
  DashboardPage,
  ProgressTracker,
  Reports,
  ApprovalsMapDashboard,
  RecentTrialsMap,
  CombinationsReports,
  ResearchBriefs,
  ConferencesDebriefs,
  ConferencesDeepDives,
}
