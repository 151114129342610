// Return path or combine array of path into a string
// Can handle array within array
export default (path: string | Array<string | string[] | undefined>) => {
  if (typeof path === 'string') {
    return path
  }
  return (
    '/' +
    path
      .flat()
      .filter((x) => x !== undefined)
      .filter((x) => !!x)
      .filter((x) => x !== '$')
      .join('/')
  )
}
