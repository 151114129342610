import { INavigationMenu } from 'interfaces/navigationMenu.interface'

export type Covid19CommercialTherapeuticsSlugs =
  | ''
  | 'organisations'
  | 'dashboard'
  | 'developers'
  | 'manufacturers'
  | 'supply'
  | 'overview'
  | 'timeseries'
  | 'production'
  | 'price'
  | 'revenue'
  | 'to-date'
  | 'forecast-report'
  | 'reports'
  | 'production-agreements-dashboard'
  | 'production-agreements-table'
  | 'uptake'
  | 'country-profile'
  | 'research-briefs'
  | 'demand'
  | 'eligible-population'
  | 'treatment-uptake'
  | 'demand-report'
  | 'manufacturing-and-production'
  | 'supply-deals-dashboard'
  | 'supply-deals-table'
  | 'table'
  | 'pricing-dashboard'

const Covid19CommercialTherapeuticsMenu: Array<
  INavigationMenu<Covid19CommercialTherapeuticsSlugs>[]
> = [
  [
    {
      title: 'Demand',
      slug: 'demand',
      defaultOpen: true,
      createdAt: new Date(2024, 1, 31),
      children: [
        {
          title: 'Treatment Uptake Dashboard',
          slug: 'treatment-uptake',
          defaultOpen: true,
          createdAt: new Date(2024, 8, 2),
        },
        {
          title: 'Treatment Demand Reports',
          slug: 'demand-report',
          createdAt: new Date(2024, 8, 2),
        },
        {
          title: 'Eligible Population Table',
          slug: 'eligible-population',
          createdAt: new Date(2024, 1, 31),
        },
      ],
    },
    {
      title: 'Manufacturing & Production',
      slug: 'manufacturing-and-production',
      createdAt: new Date(2024, 6, 21),
      children: [
        {
          title: 'Production Agreements Table',
          slug: 'production-agreements-table',
          createdAt: new Date(2024, 6, 21),
        },
        {
          title: 'Production Agreements Dashboard',
          slug: 'production-agreements-dashboard',
          createdAt: new Date(2024, 6, 21),
        },
      ],
    },
    {
      title: 'Supply & Pricing',
      slug: 'supply',
      createdAt: new Date(2024, 6, 21),
      children: [
        {
          title: 'Supply & Pricing Table',
          slug: 'supply-deals-table',
          createdAt: new Date(2024, 6, 21),
        },
        {
          title: 'Historical Supply Deals Dashboard',
          slug: 'supply-deals-dashboard',
          createdAt: new Date(2024, 6, 21),
        },
        {
          title: 'Pricing Dashboard',
          slug: 'pricing-dashboard',
          createdAt: new Date(2024, 8, 2),
        },
      ],
    },
    {
      title: 'Revenue',
      slug: 'revenue',
      createdAt: new Date(2022, 3, 27),
      children: [
        {
          title: 'Revenue Table',
          slug: 'table',
          createdAt: new Date(2024, 8, 2),
        },
        {
          title: 'Revenue Dashboard',
          slug: 'dashboard',
          createdAt: new Date(2024, 8, 2),
        },
      ],
    },
    {
      title: 'Reports',
      slug: 'reports',
      createdAt: new Date(2022, 4, 5),
      children: [
        { title: 'Reports', slug: '', createdAt: new Date(2022, 3, 13) },
        {
          title: 'Research Briefs',
          slug: 'research-briefs',
          createdAt: new Date(2022, 7, 24),
        },
      ],
    },
  ],
]

export { Covid19CommercialTherapeuticsMenu }
