import { Box } from '@chakra-ui/react'
import { createRef, useEffect, useRef, useState } from 'react'
import { Document, Page } from 'react-pdf'
import { useAppParams } from 'routes/utils'
import useTracking from 'tracking/useTracking'
import useIsMobile from 'utils/useIsMobile'

type PdfViewerProps = {
  reportUrl: string
  reportId?: string
  reportTitle?: string
  reportType?: string
}

export const PdfViewer = ({
  reportUrl,
  reportId,
  reportTitle,
  reportType,
}: PdfViewerProps) => {
  const pageRefs = useRef<any[]>([])
  const [windowWidth, setWindowWidth] = useState<number>(400)
  const [numPages, setNumPages] = useState<number>()

  const [isMobile] = useIsMobile()
  const [tracking] = useTracking()
  const { app, pageSlug } = useAppParams()

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth)
    }
    window.addEventListener('resize', handleResize)
    handleResize()
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  function onDocumentLoadSuccess({ numPages }: { numPages: number }): void {
    setNumPages(numPages)
    pageRefs.current = new Array(numPages).fill(null).map(() => createRef())
  }
  function onDocumentLoadError(error: Error): void {
    console.error('Error loading PDF:', error)
    // Handle error state
  }

  function handleClick({ pageNumber }: { pageNumber: number }) {
    tracking.clickedOnPdfIndex({
      app,
      page: pageSlug,
      reportId: reportId ?? '',
      type: reportType ?? '',
      report: reportTitle ?? '',
    })
    const targetRef = pageRefs.current[pageNumber - 1]
    if (targetRef?.current) {
      targetRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' })
    }
  }

  const pages = new Array(numPages).fill(1)

  const width = windowWidth / (isMobile ? 1.1 : 2)

  return (
    <Document
      className='pdf-document'
      file={reportUrl}
      onLoadSuccess={onDocumentLoadSuccess}
      onLoadError={onDocumentLoadError}
      onItemClick={handleClick}
      externalLinkTarget='_blank'
      externalLinkRel='noopener noreferrer'
      loading={() => <Box h={width / 1.77} bg='gray.300' width={width} />}
    >
      <Box p='4px'>
        {pages.map((_, index) => {
          return (
            <Box key={index} py='8px' ref={pageRefs.current[index]}>
              <Page
                pageNumber={index + 1}
                width={width}
                loading={() => (
                  <Box h={width / 1.77} bg='gray.300' width={width} />
                )}
              />
            </Box>
          )
        })}
      </Box>
    </Document>
  )
}

export default PdfViewer
