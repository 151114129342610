import { Flex } from '@chakra-ui/react'

import 'react-pdf/dist/Page/TextLayer.css'
import 'react-pdf/dist/Page/AnnotationLayer.css'
import { useSharedReport } from 'api/cms/reports/useReports'
import { LoadingAnimation } from 'components'

import './styles.css'
import { useParams } from 'react-router-dom'
import PdfViewer from '../SecureReportById/PdfViewer'

const SharedReport = () => {
  const params = useParams()
  const reportSlug = params.slug || ''

  const { data: reportData } = useSharedReport(reportSlug)

  if (!reportData?.url) return <LoadingAnimation />

  return (
    <Flex
      justifyContent={'center'}
      bg='gray.100'
      py='0.5rem'
      overflowY='scroll'
    >
      <PdfViewer
        reportId={reportData.id ? reportData.id.toString() : ''}
        reportTitle={reportData.title ?? ''}
        reportUrl={reportData.url}
      />
    </Flex>
  )
}

export default SharedReport
